export const getOrderPath = id => {
  return `/orders/${id}`;
};

export const getUserPath = id => {
  return `/users/${id}`;
};

export const getDriverPath = id => {
  return `/drivers/${id}`;
};

export const getOrgAdminPath = id => {
  return `/admin/organizations/${id}`;
};
